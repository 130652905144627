import React from 'react';
import P from '../../../elements/Paragraph';
import {
	isAssignedPlannerOrAdmin,
	linkObject,
	toSimpleLink,
	getDetailLink,
} from '../../../lib/helpers/index';
import { linkCustomer, LinkCustomerLinkType } from '../helpers';
import { ProposalsIcon } from '../../../mui/Icons/index';
import { LinkObjectType } from '../../../lib/helpers/types';
import { EventCategory, UserType } from '../../../types/generated';
import { FlattenedUser } from '../../../types/user';

export default function getProposalSentEventProps(
	defaultEventProps: {
		event: any;
		message: JSX.Element;
		links: { type: string; text: string; link: any }[];
		icon: JSX.Element;
		className: string;
	},
	user: FlattenedUser,
	targetUser: { [index: string]: any }
) {
	const updatedEventProps = { ...defaultEventProps };
	const isResentProposalEvent =
		updatedEventProps.event.category === EventCategory.ProposalResent;
	const detailLink = getDetailLink( {
		object: updatedEventProps.event.proposal,
		type: LinkObjectType.Proposal,
	} );
	const userIsCustomer = user?.isLoggedIn
		? updatedEventProps.event.contact.customer.id === user.user.id
		: false;
	const userCanActOnProposal =
		userIsCustomer ||
		( isAssignedPlannerOrAdmin( user, updatedEventProps.event.clientUser ) &&
			user?.isLoggedIn &&
			user.userType === UserType.OrgUser &&
			user.organization.id !== updatedEventProps.event.vendor.id );
	const proposalLink = linkObject( updatedEventProps.event.proposal );
	const vendorLink = linkObject( updatedEventProps.event.vendor );
	const clientLink = linkCustomer(
		updatedEventProps.event,
		LinkCustomerLinkType.Contact
	);

	updatedEventProps.icon = <ProposalsIcon />;
	updatedEventProps.links = [
		{
			type: userCanActOnProposal ? 'primary' : 'secondary',
			text: userCanActOnProposal ? 'Accept' : 'View',
			link: detailLink,
		},
	];
	if ( userIsCustomer ) {
		isResentProposalEvent
			? ( updatedEventProps.message = (
				<P className='p--secondary'>
					{ toSimpleLink( {
						link: detailLink,
						text: updatedEventProps.event.proposal.title,
					} ) }{ ' ' }
						has been resent by { vendorLink }.
				</P>
			  ) )
			: ( updatedEventProps.message = (
				<P className='p--secondary'>
						New{ ' ' }
					{ toSimpleLink( {
						link: detailLink,
						text: 'proposal',
					} ) }{ ' ' }
						from { vendorLink }.
				</P>
			  ) );
	}

	// Planner Viewer
	else if ( isAssignedPlannerOrAdmin( user, updatedEventProps.event.clientUser ) ) {
		// Viewing assigned client's profile
		if ( targetUser?.user.userType === 'ClientUser' ) {
			isResentProposalEvent
				? ( updatedEventProps.message = (
					<P>
						{ toSimpleLink( {
							link: detailLink,
							text: updatedEventProps.event.proposal.title,
						} ) }{ ' ' }
							has been resent by { vendorLink }.
					</P>
				  ) )
				: ( updatedEventProps.message = (
					<P className='p--secondary'>
							New{ ' ' }
						{ toSimpleLink( {
							link: detailLink,
							text: 'proposal',
						} ) }{ ' ' }
							from { vendorLink }.
					</P>
				  ) );

			// Viewing homepage feed
		} else {
			// Is in org that sent proposal
			if (
				user?.isLoggedIn &&
				user.userType === UserType.OrgUser &&
				updatedEventProps.event.vendor.id === user.organization.id
			) {
				isResentProposalEvent
					? ( updatedEventProps.message = (
						<P>
								Your organization resent { clientLink } a proposal: { proposalLink }
								.
						</P>
					  ) )
					: ( updatedEventProps.message = (
						<P className='p--secondary'>
								Your organization sent { clientLink } a proposal: { proposalLink }.
						</P>
					  ) );

				// Is not in org that sent proposal
			} else {
				isResentProposalEvent
					? ( updatedEventProps.message = (
						<P>
							{ vendorLink } resent a proposal to { clientLink }:{ ' ' }
							{ toSimpleLink( {
								link: detailLink,
								text: updatedEventProps.event.proposal.title,
							} ) }
						</P>
					  ) )
					: ( updatedEventProps.message = (
						<P className='p--secondary'>
							{ clientLink } has a new proposal from { vendorLink }.
						</P>
					  ) );
			}
		}
	} else if ( user?.isLoggedIn && user.userType === UserType.OrgUser ) {
		isResentProposalEvent
			? ( updatedEventProps.message = (
				<P>
						You resent { clientLink } a proposal: { proposalLink }.
				</P>
			  ) )
			: ( updatedEventProps.message = (
				<P>
						You sent { clientLink } a proposal: { proposalLink }.
				</P>
			  ) );
	}

	return updatedEventProps;
}
